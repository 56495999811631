import NoOrdersFoundImg from 'assets/images/no-orders-found.svg';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';

import BirdiAccordion from 'ui-kit/accordion/accordion';
import BackgroundImage from 'ui-kit/background-image/background-image';
import DropdownCustom from 'ui-kit/dropdown-custom';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import PageSection from 'ui-kit/page-section/page-section';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import PageLayout from 'components/layouts/page/page.layout';
import OrderInfo from 'components/order-info/order-info';
import { OrderInvoiceForm } from 'components/order-invoice/order-invoice-form.component';
import Pagination from 'components/pagination/pagination';

import { accountFetchOrderHistoryRoutine, accountFetchOrderLinesRoutine } from 'state/account/account.routines';
import { accountProfilEPostPatientNumSelector, accountStateSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { getPatientCart } from 'state/cart/cart.helpers';
import { cartSelector } from 'state/cart/cart.selectors';

import './order-history.style.scss';

const PAGE_SIZE = 10;

interface Option {
    label: string;
    value: string;
    onClick: () => void;
    duration: number;
    unit: moment.unitOfTime.DurationConstructor; // 'days', 'months', 'years'.
}

const generateOptions = (handleSelectFilterDate: (filterOption: string) => void): Option[] => [
    {
        label: '6 months',
        value: '6 months',
        onClick: () => handleSelectFilterDate('6 months'),
        duration: 6,
        unit: 'months'
    },
    {
        label: '1 year',
        value: '1 year',
        onClick: () => handleSelectFilterDate('1 year'),
        duration: 1,
        unit: 'years'
    },
    {
        label: '2 years',
        value: '2 years',
        onClick: () => handleSelectFilterDate('2 years'),
        duration: 2,
        unit: 'years'
    }
];

const calculateDateRange = (filterOption: string, options: Option[]): { from: string; to: string } | null => {
    const selectedOption = options.find((option) => option.value === filterOption);
    if (!selectedOption) return null;

    const { duration, unit } = selectedOption;

    return {
        from: moment().subtract(duration, unit).startOf('day').format('MM.DD.YYYY'),
        to: moment().endOf('day').format('MM.DD.YYYY')
    };
};

const OrderHistory = ({ data }: { data: GatsbyTypes.OrderHistoryDataQuery }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { orderHistory } = useSelector(accountStateSelector);
    const epostPatientNum = useSelector(accountProfilEPostPatientNumSelector);
    const [currentPage, setCurrentPage] = useState(1);
    const [ordersFetched, setOrdersFetched] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [filterDate, setFilterDate] = useState<string>('6 months');
    const cartObject = useSelector(cartSelector);
    const cartOrderNumber = getPatientCart(cartObject, epostPatientNum)?.Order?.orderHeader.orderNum;

    const hasNextPage = useMemo(() => orderHistory && orderHistory.length === PAGE_SIZE, [orderHistory]);

    // Filter for a list of orders that do not match the order currently in the cart.
    // If the order history or cart order number is not yet available, return an
    // empty array.
    const filteredOrders = useMemo(() => {
        if (ordersFetched && orderHistory && cartOrderNumber !== undefined) {
            // If the cart is empty, then cartOrderNumber will be null.
            if (cartOrderNumber === null) {
                return orderHistory;
            }

            return orderHistory.filter(function (order) {
                return order.epostOrderNum !== cartOrderNumber;
            });
        }

        // If orderHistory and the cart have not been loaded yet, then just
        // return null.
        return null;
    }, [orderHistory, cartOrderNumber, ordersFetched]);

    const showLoading = filteredOrders === null;

    const handleSelectFilterDate = (filterOption: string) => {
        setFilterDate(filterOption);
        setCurrentPage(1);
    };

    const options = generateOptions(handleSelectFilterDate);

    const handleGetOrderLines = (orderNum: string) => {
        dispatch(
            accountFetchOrderLinesRoutine.trigger({
                epostOrderNum: orderNum
            })
        );
    };

    const handleNextPageClick = () => {
        setCurrentPage(currentPage + 1);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handlePrevPageClick = () => {
        const nextPage = currentPage - 1;
        setCurrentPage(nextPage < 1 ? 1 : nextPage);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const handleReportFormCancel = () => {
        dispatch(closeModal({}));
    };

    const handleReportClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'get-report-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t(`modals.generateReport.title`)}
                        body={
                            <OrderInvoiceForm
                                handleMessagesFormCancel={handleReportFormCancel}
                                centeredButtons={true}
                            />
                        }
                    />
                ),
                ctas: [],
                overflow: 'unset'
            })
        );
    };
    // Order history can go all the way back to 2014. Since we're retrieving
    // paginated results, it doesn't hurt to go back further just to be safe.
    const fetchOrderHistory = useCallback(() => {
        // Reset the error and fetched state in case we're moving between pages.
        setFetchError(false);
        setOrdersFetched(false);

        const dateRange = calculateDateRange(filterDate, options);

        if (!dateRange) return;

        // Fetch the orders.
        dispatch(
            accountFetchOrderHistoryRoutine.trigger({
                from: dateRange.from,
                to: dateRange.to,
                page: currentPage.toString(),
                pageSize: PAGE_SIZE,
                onSuccess: () => setOrdersFetched(true),
                onFailure: () => setFetchError(true)
            })
        );
    }, [dispatch, currentPage, filterDate]);

    useEffect(() => {
        fetchOrderHistory();
    }, [dispatch, currentPage, fetchOrderHistory, filterDate]);

    return (
        <PageLayout metaData={{ nodeTitle: 'Order History' }}>
            <BackgroundImage image={data.blueSkyBackground}>
                <PageSection className="order-history-page-section">
                    <div
                        className={`order-history ${filteredOrders?.length === 0 && 'order-history--empty'}`}
                        id="export"
                    >
                        <div className="order-history-header">
                            <div className="h5 eyebrow-text">{t('pages.orderHistory.eyebrowText')}</div>
                            <h1 className="h2">{t('pages.orderHistory.title')}</h1>
                            <div className="spacer" />
                        </div>

                        <div className="order-history-filter">
                            <div className="order-history-filter-content">
                                <p>{t('pages.orderHistory.filter.label')}</p>
                                <DropdownCustom label={filterDate} options={options} />
                            </div>

                            <ButtonComponent
                                variant="outline"
                                label={t('pages.orderHistory.getInvoice.buttons.openModal')}
                                onClick={handleReportClick}
                            />
                        </div>

                        {showLoading ? (
                            <LoadingMessage
                                className="mb-5"
                                isVisible={true}
                                text={t('pages.orderHistory.messages.retrievingOrderHistory')}
                            />
                        ) : (
                            <>
                                {filteredOrders && filteredOrders?.length > 0 ? (
                                    <>
                                        <BirdiAccordion className="order-history-accordion">
                                            <>
                                                {fetchError && (
                                                    <div className="h5 d-flex justify-content-center mb-5">
                                                        {t('pages.orderHistory.messages.fetchError')}
                                                    </div>
                                                )}
                                                {!fetchError && (
                                                    <>
                                                        <LoadingMessage
                                                            className="mb-5"
                                                            isVisible={showLoading}
                                                            text={t(
                                                                'pages.orderHistory.messages.retrievingOrderHistory'
                                                            )}
                                                        />
                                                        {filteredOrders &&
                                                            filteredOrders.length > 0 &&
                                                            filteredOrders.map((order) => {
                                                                return (
                                                                    <OrderInfo
                                                                        key={order.epostOrderNum}
                                                                        order={order}
                                                                        onGetOrderLines={handleGetOrderLines}
                                                                        translation={t}
                                                                        accordion={true}
                                                                    />
                                                                );
                                                            })}
                                                        {filteredOrders && filteredOrders.length === 0 && (
                                                            <div className="h5 d-flex justify-content-center mb-5">
                                                                {t('pages.orderHistory.messages.noOrderHistory')}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        </BirdiAccordion>
                                        <div className="order-history-pagination">
                                            <Pagination
                                                currentPage={currentPage}
                                                isNextPageDisabled={!hasNextPage}
                                                onNextPageClick={handleNextPageClick}
                                                onPreviousPageClick={handlePrevPageClick}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="order-history__empty-state">
                                        <div className="order-history__empty-state__content">
                                            <img src={NoOrdersFoundImg} alt="no-orders-found" />
                                            <h2>{t('pages.orderHistory.empty.title')}</h2>
                                            <p>
                                                {t('pages.orderHistory.empty.description', { timeframe: filterDate })}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {filteredOrders && (filteredOrders?.length === 0 || filterDate === '2 years') && (
                            <div
                                className={`order-history-disclaimer ${
                                    filteredOrders.length === 0 && 'order-history-disclaimer--centered'
                                }`}
                            >
                                <ToastBox icon="info" iconColor="cathams-blue" backgroundColor="alice-blue-tint">
                                    <div className="order-history-disclaimer__content">
                                        <p>{t('pages.orderHistory.messages.disclaimer.partOne')}</p>
                                        <ButtonComponent
                                            variant="link"
                                            className="link-button"
                                            onClick={handleReportClick}
                                        >
                                            {t('pages.orderHistory.messages.disclaimer.partTwo')}
                                        </ButtonComponent>
                                    </div>
                                </ToastBox>
                            </div>
                        )}
                    </div>
                </PageSection>
            </BackgroundImage>
        </PageLayout>
    );
};

export default OrderHistory;

export const query = graphql`
    query OrderHistoryData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/blue-sky.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
