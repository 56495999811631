import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import { DatePicker } from 'ui-kit/date-picker/date-picker';

import { accountClearOrderInvoiceRoutine, accountFetchOrderGetInvoiceRoutine } from 'state/account/account.routines';
import { accountStateSelector } from 'state/account/account.selectors';

import { invoiceMinDate } from 'const/options';

import { ORDER_INVOICE_SCHEMA } from 'schema/order-invoice';

export interface GetInvoice {
    startDate: Date;
    endDate: Date;
}

export const OrderInvoiceForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { orderInvoice } = useSelector(accountStateSelector);
    const formName = 'OrderInvoiceForm';
    const anchorRef = useRef(null);
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const startDate = new Date(today.getFullYear(), 0, 1).toISOString().slice(0, 10);
    const endDate = today.toISOString().slice(0, 10);
    const [minDate, setMinDate] = useState(invoiceMinDate);
    const [maxDate, setMaxDate] = useState(endDate);

    useEffect(() => {
        if (orderInvoice && typeof window !== 'undefined') {
            const blob = new Blob([orderInvoice], { type: 'application/pdf' });
            const pdfBlob = window.URL.createObjectURL(blob);

            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if (isSafari) {
                // safari not accept popup so we need to use anchor click
                const link = document.createElement('a');
                link.href = pdfBlob;
                link.target = '_blank';
                link.download = 'invoice.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.open(pdfBlob, '_blank');
            }

            dispatch(accountClearOrderInvoiceRoutine.trigger());
        }
    }, [orderInvoice, dispatch]);

    const convertIsoToLocale = (value: string) => {
        if (value.length) {
            const event = new Date(value);
            event.setUTCHours(0, 0, 0, 0);
            return event.toLocaleString('en-US', { timeZone: 'UTC' });
        }
        return '';
    };

    const handleFormSubmit = (
        values: Partial<GetInvoice>,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
        dispatch(
            accountFetchOrderGetInvoiceRoutine.trigger({
                from: convertIsoToLocale(values.startDate),
                to: convertIsoToLocale(values.endDate),
                onSuccess: () => setSubmitting(false),
                onFailure: () => setSubmitting(false)
            })
        );
    };
    return (
        <Formik<Partial<GetInvoice>>
            onSubmit={handleFormSubmit}
            validationSchema={ORDER_INVOICE_SCHEMA(endDate)}
            initialValues={{
                startDate: startDate,
                endDate: endDate
            }}
        >
            {(formik: any) => (
                <Form
                    id="get-invoice-form"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className=""
                    data-ga-form-name={formName}
                >
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <DatePicker
                                name="startDate"
                                identifier="startDate"
                                onChange={(data: any) => {
                                    formik.handleChange(data);
                                    setMinDate(data.detail.value);
                                    formik.setFieldTouched('startDate');
                                }}
                                errors={
                                    formik.errors?.startDate
                                        ? formik.errors.startDate.includes('Invalid Date')
                                            ? t(`modals.generateReport.errors.startDateInvalidDate`)
                                            : t(`modals.generateReport.errors.startDate${formik.errors.startDate}`)
                                        : undefined
                                }
                                min={invoiceMinDate}
                                max={maxDate}
                                labelFor="startDate"
                                label={t('modals.generateReport.startDate')}
                                touched={formik.touched.startDate}
                                value={formik.initialValues.startDate}
                                autoComplete="off"
                            />
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <DatePicker
                                name="endDate"
                                onChange={(data: any) => {
                                    formik.handleChange(data);
                                    setMaxDate(data.detail.value);
                                    formik.setFieldTouched('endDate');
                                }}
                                identifier="endDate"
                                errors={
                                    formik.errors?.endDate
                                        ? formik.errors.endDate.includes('Invalid Date')
                                            ? t(`modals.generateReport.errors.endDateInvalidDate`)
                                            : t(`modals.generateReport.errors.endDate${formik.errors.endDate}`)
                                        : undefined
                                }
                                labelFor="endDate"
                                label={t('modals.generateReport.endDate')}
                                min={minDate}
                                max={endDate}
                                touched={formik.touched.endDate}
                                value={formik.initialValues.endDate}
                                autoComplete="off"
                            />
                        </Col>
                        {
                            // eslint-disable-next-line
                            <a ref={anchorRef} aria-hidden="true" className="d-none" href="#" />
                        }
                    </Row>
                    <Row className={`flex-column mt-3` + (props.centeredButtons ? ` text-center` : ``)}>
                        <Col>
                            <Button
                                async
                                className="sm-full md-full"
                                label={t('modals.generateReport.export')}
                                variant="primary"
                                type="submit"
                                disabled={
                                    formik.errors.startDate ||
                                    formik.errors.endDate ||
                                    !formik.values.startDate.length ||
                                    !formik.values.endDate.length ||
                                    formik.isSubmitting
                                }
                                onClick={formik.handleSubmit}
                                dataGAFormName={formName}
                                isBusy={formik.isSubmitting}
                            />
                        </Col>
                        {typeof props.handleMessagesFormCancel !== 'undefined' &&
                            typeof props.handleMessagesFormCancel !== 'object' && (
                                <Col className={`mt-3`}>
                                    <Button
                                        label={t('pages.profile.messages.labels.cancel')}
                                        className="md-full md-pad-y-2"
                                        type="button"
                                        variant="text"
                                        onClick={props.handleMessagesFormCancel}
                                        dataGAFormName={formName}
                                    />
                                </Col>
                            )}
                    </Row>
                </Form>
            )}
        </Formik>
    );
};
